export const ADD_APP = 'ADD_APP';
export const DEL_APP = 'DEL_APP';
export const FOCUS_APP = 'FOCUS_APP';
export const MINIMIZE_APP = 'MINIMIZE_APP';
export const TOGGLE_MAXIMIZE_APP = 'TOGGLE_MAXIMIZE_APP';
export const FOCUS_ICON = 'FOCUS_ICON';
export const SELECT_ICONS = 'SELECT_ICONS';
export const FOCUS_DESKTOP = 'FOCUS_DESKTOP';
export const START_SELECT = 'START_SELECT';
export const END_SELECT = 'END_SELECT';
export const POWER_OFF = 'POWER_OFF';
export const BLUE_ERROR_OFF = 'BLUE_ERROR_OFF';
export const CANCEL_BLUE_ERROR_OFF = 'CANCEL_BLUE_ERROR_OFF';
export const CANCEL_POWER_OFF = 'CANCEL_POWER_OFF';
